<template>
  <b-card title="العملاء">
    <b-tabs>
    <b-tab active >
        <template #title>
          <a
            href="https://sys.ar.lpcentre.com/add-client"
            class="no-color"
          >
          <feather-icon icon="PlusIcon" />
          <span>إضافة عميل</span>
          </a>
        </template>

 <AddClient></AddClient>
      </b-tab>
      <b-tab @click="$router.push('/all-client')"> 
        <template #title>
          <a
            href="https://sys.ar.lpcentre.com/all-client"
            class="no-color"
          >
          <feather-icon icon="HomeIcon" />
          <span> كل العملاء </span>
          </a>
        </template>
        <router-view></router-view>
   <!-- <AllClients></AllClients> -->
      </b-tab>
      
       
     
    </b-tabs>

  </b-card>
</template>

<script>

import { BTabs, BTab, BCardText,BCard } from 'bootstrap-vue'


import AllClients from './AllClients.vue';
import AddClient from './AddClient.vue';


export default {
  components: {
    BCard,
    BCardText,
    BTabs,
    BTab,
  
    AllClients,
    AddClient
},
  data() {
    return {
   
    }
  },
}
</script>
